package admin.ui.home

import admin.model.DataRepository
import admin.navigation.NavigationState
import androidx.compose.runtime.mutableStateOf
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import org.jetbrains.compose.resources.DrawableResource

class AdminHomeViewModel(
    private val dataRepository: DataRepository,
) {
    sealed class MenuItem(val text: String, val icon: DrawableResource?, val onClick: (MenuItem) -> Unit) {
        var selected = mutableStateOf(false)

        class MerchantDemoManager(onClick: (MenuItem) -> Unit) : MenuItem("'Demo' Manager", icon = null, onClick = onClick)
        class Decryption(onClick: (MenuItem) -> Unit) : MenuItem("decryption", icon = null, onClick = onClick)
        class CoalitionBilling(onClick: (MenuItem) -> Unit) : MenuItem("coalition billing tools", icon = null, onClick = onClick)
        class ManageLinxDemo(onClick: (MenuItem) -> Unit) : MenuItem("ability to modify 555 demo account in linx", icon = null, onClick = onClick)
        class LinxSmsIntercept(onClick: (MenuItem) -> Unit) : MenuItem("Linx SMS Hacker", icon = null, onClick = onClick)
        class NameIdLookup(onClick: (MenuItem) -> Unit) : MenuItem("Name/Id Lookup", icon = null, onClick = onClick)
        class CoalitionCreator(onClick: (MenuItem) -> Unit) : MenuItem("Coalitions", icon = null, onClick = onClick)
        class TerminalInfo(onClick: (MenuItem) -> Unit) : MenuItem("Terminal Info", icon = null, onClick = onClick)
        class MerchantActivity(onClick: (MenuItem) -> Unit) : MenuItem("Merchant Activity", icon = null, onClick = onClick)
        class LocationNearbyGroupManager(onClick: (MenuItem) -> Unit) : MenuItem("Nearby Manager", icon = null, onClick = onClick)
        class LinxAnalytics(onClick: (MenuItem) -> Unit) : MenuItem("Linx Analytics", icon = null, onClick = onClick)
    }

    private val menuItemsMutableStateFlow = MutableStateFlow(listOf(
        MenuItem.MerchantDemoManager(::menuItemOnClick),
        MenuItem.LinxSmsIntercept(::menuItemOnClick),
        MenuItem.NameIdLookup(::menuItemOnClick),
        MenuItem.CoalitionCreator(::menuItemOnClick),
        MenuItem.TerminalInfo(::menuItemOnClick),
        MenuItem.MerchantActivity(::menuItemOnClick),
        MenuItem.LocationNearbyGroupManager(::menuItemOnClick),
        MenuItem.LinxAnalytics(::menuItemOnClick),
    ))

    val menuItemsStateFlow = menuItemsMutableStateFlow.asStateFlow()

    val navigationStateFlow: StateFlow<NavigationState> = dataRepository.navigationStateFlow

    fun logout() {
        dataRepository.deleteToken()
        dataRepository.navigate(NavigationState.Login())
    }

    private fun menuItemOnClick(menuItem: MenuItem) {
        menuItemsMutableStateFlow.value.forEach {
            it.selected.value = it == menuItem
        }
        when (menuItem) {
            is MenuItem.CoalitionBilling -> { }
            is MenuItem.Decryption -> { }
            is MenuItem.LinxSmsIntercept -> { dataRepository.navigate(NavigationState.LinxSmsIntercept()) }
            is MenuItem.ManageLinxDemo -> { }
            is MenuItem.MerchantDemoManager -> { dataRepository.navigate(NavigationState.MerchantDemoManager()) }
            is MenuItem.NameIdLookup -> { dataRepository.navigate(NavigationState.NameId()) }
            is MenuItem.CoalitionCreator -> { dataRepository.navigate(NavigationState.CoalitionCreator()) }
            is MenuItem.TerminalInfo -> { dataRepository.navigate(NavigationState.TerminalInfo()) }
            is MenuItem.MerchantActivity -> { dataRepository.navigate(NavigationState.MerchantActivity()) }
            is MenuItem.LocationNearbyGroupManager -> { dataRepository.navigate(NavigationState.LocationNearbyGroupManager()) }
            is MenuItem.LinxAnalytics -> { dataRepository.navigate(NavigationState.LinxAnalytics()) }
        }
    }

    init {
        menuItemsMutableStateFlow.value.first().also { menuItemOnClick(it) }.selected.value = true
    }
}