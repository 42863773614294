package model

enum class EventType(val eventType: String) {
    LOGINSUCCESS("loginSuccess"),
    DELETECARD("deleteCard"),
    MERCHANTNEARME("merchantNearMe"),
    //    MERCHANTNEARMECLICKED("merchantNearMeClicked"),??
    ERECEIPT("eReceipt"), //consumer is looking at the ereceipt
    ERECEIPTMERCHANT("eReceiptMerchant"), //Merchant is looking for eReceipt at the portal
    CANCELENROLLMENT("cancelEnrollment"),
    VIEWMERCHANT("viewMerchant"), //related to when the user click on a merchant
    VIEWHOME("viewHome") //related to when the user hits home
    ;
}