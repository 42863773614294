package model

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class LinxAnalyticsRequestBody(
    val from: LocalDateTime,
    val to: LocalDateTime,
)
