package ui.eReceipt

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.selection.DisableSelection
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.CircularProgressIndicator
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.flow.StateFlow
import model.ConsumerInformationV2
import model.MerchantFullInformationV2
import receipt.OptInTypes
import receipt.Question
import receipt.Receipt
import receipt.hasFlag
import ui.Colors

val eReceiptMaxWidth = 300.dp

@Composable
fun EReceiptScreen(
    eReceiptStateFlow: StateFlow<Receipt?>,
    onCancelEnrollmentClicked: (String) -> Unit,
    onTermsOfServiceClicked: () -> Unit,
    onPrivacyPolicyClicked: () -> Unit,
    onManageAccountClicked: (String) -> Unit,
    isTokenAvailable: Boolean,
    questionStateFlow: StateFlow<Question?>,
    onScore: (String, Int, Int) -> Unit,
    merchantInformationStateFlow: StateFlow<MerchantFullInformationV2?>,
    consumerInformationStateFlow: StateFlow<ConsumerInformationV2?>,
) {
    val scrollState = rememberScrollState()
    val maxWidth = eReceiptMaxWidth
    SelectionContainer {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .background(Colors.Default)
                .padding(top = 8.dp)
                .verticalScroll(scrollState),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {

            val _eReceipt by eReceiptStateFlow.collectAsState()
            val eReceipt = _eReceipt

            if (eReceipt != null) {
                val consumerInformation by consumerInformationStateFlow.collectAsState()
                val loggedInCustomerIdMatchesReceiptCustomerId = consumerInformation?.consumer?.consumerId != eReceipt.consumerId
                if (consumerInformation != null && loggedInCustomerIdMatchesReceiptCustomerId) {
                    DifferentCustomerBanner(maxWidth)
                    Spacer(
                        modifier = Modifier
                            .height(8.dp)
                            .widthIn(0.dp, maxWidth),
                    )
                }
                PerforatedEdge(
                    maxWidth = maxWidth
                )
                Divider(
                    modifier = Modifier
                        .height(32.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                LocationTitleBanner(eReceipt.locationDetails, maxWidth)
                Box(modifier = Modifier
                    .widthIn(0.dp, maxWidth)
                    .background(Color.White)
                ) {
                    OrderInformation(eReceipt)
                }
                Divider(
                    modifier = Modifier
                        .height(4.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                ReceiptContent(eReceipt, maxWidth)
                Balance(
                    eReceipt.rewardBalanceMessage,
                    merchantInformationStateFlow,
                    loggedInCustomerIdMatchesReceiptCustomerId,
                    maxWidth
                )
                Divider(
                    modifier = Modifier
                        .height(16.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                eReceipt.transactionDetails.filter { it.key != "Order ID" && it.key != "Date" && it.key != "Time" }.let {
                    TransactionInformation(it, maxWidth)
                }
                Divider(
                    modifier = Modifier
                        .height(32.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                PerforatedEdge(
                    isTopPosition = false,
                    maxWidth = maxWidth
                )
                DisableSelection {
                    if (!merchantInformationStateFlow.value?.optInType.hasFlag(OptInTypes.OptedOut)) {
                        QuestionSection(
                            questionStateFlow = questionStateFlow,
                            onScore = { questionId, rating ->
                                onScore(eReceipt.orderPaymentGuid, questionId, rating)
                            },
                            maxWidth
                        )
                    }
                    Links(
                        isTokenAvailable = isTokenAvailable,
                        currentCustomerIdMatchesExistingCustomerId = loggedInCustomerIdMatchesReceiptCustomerId,
                        maxWidth = maxWidth,
                        consumerOptInType = merchantInformationStateFlow.value?.optInType,
                        onCancelEnrollmentClicked = {
                            onCancelEnrollmentClicked(eReceipt.consumerLoyaltyProgramGuid.orEmpty())
                        },
                        onTermsOfServiceClicked = onTermsOfServiceClicked,
                        onPrivacyPolicyClicked = onPrivacyPolicyClicked,
                    ) {
                        onManageAccountClicked(eReceipt.orderPaymentGuid)
                    }
                }
            } else {
                CircularProgressIndicator(
                    modifier = Modifier
                        .align(Alignment.CenterHorizontally)
                        .clearAndSetSemantics {
                            contentDescription = "Loading eReceipt"
                        }
                )
            }
        }
    }
}