package admin.ui.linxAnalytics

import admin.model.DataRepository
import androidx.compose.ui.graphics.Color
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn
import kotlinx.datetime.minus
import kotlinx.datetime.plus
import kotlinx.datetime.toLocalDateTime
import kotlinx.datetime.todayIn
import model.AdminAccess
import model.Analytics
import model.EventType
import model.Platform

class LinxAnalyticsViewModel(
    private val dataRepository: DataRepository,
    private val scope: CoroutineScope,
) {
    sealed class DialogState {
        class None: DialogState()
        class Success: DialogState()
        class Error: DialogState()
    }

    private val dialogStateMutableStateFlow = MutableStateFlow<DialogState>(DialogState.None())
    val dialogStateStateFlow = dialogStateMutableStateFlow

    sealed class TimePeriod {
        data object Today: TimePeriod()
        data object Month: TimePeriod()
        data class Custom(val from: LocalDateTime, val to: LocalDateTime): TimePeriod()
    }

    private val selectedTimePeriodMutableStateFlow = MutableStateFlow<TimePeriod>(TimePeriod.Month)
    private val selectedTimePeriodStateFlow = selectedTimePeriodMutableStateFlow.asStateFlow()

    sealed class Metric {
        data object Platform: Metric()
        data object Event: Metric()
    }

    sealed class AnaltyicsDisplay {
        data class Platform(val barGraphData: BarGraphData): AnaltyicsDisplay()
        data class Event(val barGraphData: BarGraphData): AnaltyicsDisplay()
    }

    private val selectedMetricMutableStateFlow = MutableStateFlow<Metric>(Metric.Platform)
    private val selectedMetricStateFlow = selectedMetricMutableStateFlow.asStateFlow()

    private val analyticsMutableStateFlow = MutableStateFlow<List<Analytics>>(emptyList())
    private val analyticsStateFlow = analyticsMutableStateFlow.asStateFlow()

    val metricStateFlow = combine(selectedMetricStateFlow, analyticsStateFlow, selectedTimePeriodStateFlow) { metric, analytics, selectedTimePeriod ->
        isLinxAnalyticsRefreshingMutableStateFlow.value = true
        val timePeriodLabel = when(selectedTimePeriod) {
            TimePeriod.Today -> "Today"
            TimePeriod.Month -> "This Month"
            is TimePeriod.Custom -> " ${selectedTimePeriod.from} - ${selectedTimePeriod.to}"
        }
        when (metric) {
            is Metric.Platform -> {
                AnaltyicsDisplay.Platform(BarGraphData(
                    label = "Usage by platform for: $timePeriodLabel",
                    xLabel = "Number",
                    yLabel = "Platform",
                    bars = analytics
                        .groupBy { it.platform }
                        .map { entry ->
                            GraphBar(
                                label = entry.key,
                                quantity = entry.value.size,
                                color = when (entry.key) {
                                    Platform.ANDROID.name -> Color.Green
                                    Platform.IOS.name -> Color.White
                                    Platform.WEBJS.name -> Color.LightGray
                                    Platform.WEBWASM.name -> Color.Gray
                                    else -> Color.Yellow
                                }
                            )
                        }
                ))
            }
            is Metric.Event -> {
                AnaltyicsDisplay.Event(BarGraphData(
                    label = "Usage by event for: $timePeriodLabel",
                    xLabel = "Number",
                    yLabel = "Event",
                    bars = analytics
                        .groupBy { it.eventType }
                        .map { entry ->
                            GraphBar(
                                label = entry.key,
                                quantity = entry.value.size,
                                color = when (entry.key) {
                                    EventType.LOGINSUCCESS.eventType -> Color.Cyan
                                    EventType.DELETECARD.eventType -> Color.Gray
                                    EventType.MERCHANTNEARME.eventType -> Color.White
                                    EventType.ERECEIPT.eventType -> Color.Cyan
                                    EventType.ERECEIPTMERCHANT.eventType -> Color.Green
                                    EventType.CANCELENROLLMENT.eventType -> Color.Blue
                                    EventType.VIEWMERCHANT.eventType -> Color.Magenta
                                    EventType.VIEWHOME.eventType -> Color.Red
                                    else -> Color.Yellow
                                }
                            )
                        }
                ))
            }

        }.also {
            isLinxAnalyticsRefreshingMutableStateFlow.value = false
        }
    }.stateIn(scope, SharingStarted.Eagerly, null)

    private val isLinxAnalyticsRefreshingMutableStateFlow = MutableStateFlow<Boolean>(false)
    val isLinxAnalyticsRefreshingStateFlow: StateFlow<Boolean> = isLinxAnalyticsRefreshingMutableStateFlow.asStateFlow()

    val isAdmin: Boolean by lazy {
        dataRepository.accessLevel == AdminAccess.FullAdmin
    }

    fun cancelDialog() {
        dialogStateMutableStateFlow.value = DialogState.None()
    }

    fun requestAnalyticsUpdate() {
        scope.launch {
            isLinxAnalyticsRefreshingMutableStateFlow.value = true
            val tz = TimeZone.currentSystemDefault()
            val today = Clock.System.todayIn(tz)
            val timePeriod = selectedTimePeriodStateFlow.value
            val from = when (timePeriod) {
                TimePeriod.Today -> today.atStartOfDayIn(tz).toLocalDateTime(tz)
                TimePeriod.Month -> today.minus(today.dayOfMonth - 1, DateTimeUnit.DAY).atStartOfDayIn(tz).toLocalDateTime(tz)
                is TimePeriod.Custom -> timePeriod.from
            }
            val to = when (timePeriod) {
                TimePeriod.Today -> today.plus(1, DateTimeUnit.DAY).atStartOfDayIn(tz).minus(1, DateTimeUnit.MILLISECOND).toLocalDateTime(tz)
                TimePeriod.Month -> today.minus(today.dayOfMonth, DateTimeUnit.DAY).plus(1, DateTimeUnit.MONTH).atStartOfDayIn(tz).toLocalDateTime(tz)
                is TimePeriod.Custom -> timePeriod.to
            }

            analyticsMutableStateFlow.value = dataRepository.getLinxAnalytics(from, to)
        }
    }

    fun setTimePeriod(timePeriod: TimePeriod) {
        selectedTimePeriodMutableStateFlow.value = timePeriod
        requestAnalyticsUpdate()
    }

    fun setMetric(metric: Metric) {
        selectedMetricMutableStateFlow.value = metric
        requestAnalyticsUpdate()
    }

    init {
        requestAnalyticsUpdate()
    }
}