package admin.ui.linxAnalytics
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.times
import kotlin.math.pow

@Composable
fun BarGraph(barGraphData: BarGraphData) {
    Box(Modifier.fillMaxHeight()) {
        var chartHeight by remember { mutableStateOf(0.dp) }
        var chartWidth by remember { mutableStateOf(0.dp) }
        val barPadding = 16.dp
        val incrementLabelPadding = 10.dp
        val barWidth = minOf((chartWidth - incrementLabelPadding - ((barGraphData.bars.size - 1) * barPadding)) / barGraphData.bars.size, 150.dp)
        val height = barGraphData.bars.maxOfOrNull { it.quantity } ?: 0
        val increment = getIncrements(height)
        val scale = (0..(height + increment) step increment)
        val localDensity = LocalDensity.current
        val textMeasurer = rememberTextMeasurer()
        val labelWidth = barWidth * barGraphData.bars.size + ((barGraphData.bars.size - 1) * barPadding)

        Row(verticalAlignment = Alignment.CenterVertically) {
            Text(
                modifier = Modifier.rotate(-90f),
                text = barGraphData.yLabel,
            )
            Column(Modifier,
                verticalArrangement = Arrangement.spacedBy(16.dp)
            ) {
                Text(
                    modifier = Modifier.padding(start = incrementLabelPadding + barPadding).width(labelWidth),
                    text = barGraphData.label,
                    textAlign = TextAlign.Center
                )
                Row(
                    modifier = Modifier
                        .weight(1f)
                        .fillMaxWidth()
                        .onGloballyPositioned {
                            with (localDensity) {
                                chartHeight = it.size.height.toDp()
                                chartWidth = it.size.width.toDp()
                            }
                        }
                    ,
                    verticalAlignment = Alignment.Bottom,
                    horizontalArrangement = Arrangement.spacedBy(barPadding),
                ) {
                    Box(
                        Modifier
                            .width(incrementLabelPadding)
                            .fillMaxHeight()
                            .drawBehind {
                                val strokeWidth = 1 * density
                                scale.forEach { yPosition ->
                                    val y = size.height - (size.height * (yPosition.toFloat() / scale.last))
                                    val textMeasuredSize = textMeasurer.measure("$yPosition").size
                                    drawText(
                                        textMeasurer = textMeasurer,
                                        text = "$yPosition",
                                        topLeft = Offset(-textMeasuredSize.width.toFloat() + 10f, y - (textMeasuredSize.height/2f)),
                                        size = Size(textMeasuredSize.width.toFloat(), textMeasuredSize.height.toFloat())
                                    )
                                    println("${y + (textMeasuredSize.height/2f)}")

                                    drawLine(
                                        Color.Black,
                                        Offset(10f, y),
                                        Offset(10f + 8, y),
                                        strokeWidth
                                    )
                                }
                            }
                    )
                    barGraphData.bars.forEach {
                        val barHeight = it.quantity.toFloat() / scale.last
                        Box(modifier = Modifier
                            .background(it.color)
                            .fillMaxHeight(barHeight)
                            .widthIn(min = barWidth)
                        )
                    }
                }
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.spacedBy(barPadding),
                ) {
                    Spacer(
                        Modifier
                            .width(incrementLabelPadding)
                    )
                    barGraphData.bars.forEach {
                        Text(
                            modifier = Modifier.width(barWidth),
                            text = "${ it.label } (${ it.quantity })",
                            textAlign = TextAlign.Center
                        )
                    }
                }
                Text(
                    modifier = Modifier.padding(start = incrementLabelPadding + barPadding).width(labelWidth),
                    text = barGraphData.xLabel,
                    textAlign = TextAlign.Center
                )
            }
        }
    }
}

class BarGraphData(
    val label: String,
    val xLabel: String,
    val yLabel: String,
    val bars: List<GraphBar>
)

class GraphBar(
    val label: String,
    val quantity: Int,
    val color: Color = Color.Unspecified
)

fun getIncrements(max: Int): Int {
    val power = (max - 1).toString().length - 1
    val increment = 10.0.pow(power).toInt()

    return increment
}