package model

import kotlinx.serialization.Serializable

@Serializable
data class Analytics(
    val analyticsId: Int,
    val platform: String,
    val eventType: String,
    val phoneNumber: String?,
    val consumerId: String?,
    val orderPaymentGuid: String?,
)
