package admin.model

import admin.navigation.Navigation
import admin.navigation.NavigationState
import admin.ui.snackbars.SnackBarState
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import model.AdminAccess
import model.AdminLoginResponse
import model.Analytics
import model.CoalitionAdminUser
import model.MerchantActivity
import model.PopulatedCoalition
import model.PopulatedRegion
import model.TokenAdminAuthenticationResponse

interface DataRepository {
    val navigation: Navigation
    val snackbarStateMutableStateFlow: MutableStateFlow<SnackBarState>
    val snackbarStateStateFlow: StateFlow<SnackBarState>
    val navigationStateFlow: StateFlow<NavigationState>
    val backStackStateFlow: StateFlow<List<NavigationState>>
    val populatedCoalitionsStateFlow: StateFlow<TrackedPopulatedCoalition>
    val isPopulatedCoalitionsRefreshingStateFlow: StateFlow<Boolean>
    val accessLevel: AdminAccess?

    fun setSnackbarState(state: SnackBarState)
    fun getToken(): String?
    fun deleteToken()
    fun setAccess(token: TokenAdminAuthenticationResponse)

    fun requestUpdatedPopulatedCoalitions()
    suspend fun submitCredentials(email: String, password: String): AdminLoginResponse
    suspend fun setMerchantDemo(merchantId: Int, setAsDemo: Boolean): Boolean
    suspend fun setDeviceHidden(deviceId: Int, setHidden: Boolean): Boolean
    suspend fun getMerchantActivity(): List<MerchantActivity>
    suspend fun getRegions(): List<PopulatedRegion>
    suspend fun addLocation(regionId: Int, locationId: Int): Boolean
    suspend fun removeLocation(regionId: Int, locationId: Int): Boolean
    suspend fun newRegion(regionName: String): Int?
    suspend fun getLinxAnalytics(from: LocalDateTime, to: LocalDateTime): List<Analytics>

    suspend fun submitCoalition(coalitionLongName: String, coalitionShortName: String): Boolean

    suspend fun getCoalitionAdminUsers(): List<CoalitionAdminUser>

    fun logOut()
    fun navigate(navigationState: NavigationState)
    fun popBackStack()
    fun hashStateChangeEvent(newHash: String, locationListener: (String) -> Unit)
}

class TrackedPopulatedCoalition(val populatedCoalitions: List<PopulatedCoalition> = emptyList()) {
    val updated = Clock.System.now()
}