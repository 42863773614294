package admin.model.impl

import admin.model.DataRepository
import admin.model.NetworkController
import admin.model.TrackedPopulatedCoalition
import admin.navigation.Navigation
import admin.navigation.NavigationState
import admin.persistence.AccessPersistence
import admin.persistence.TokenPersistence
import admin.ui.snackbars.SnackBarState
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDateTime
import model.AdminAccess
import model.AdminLoginResponse
import model.Analytics
import model.CoalitionAdminUser
import model.MerchantActivity
import model.PopulatedRegion
import model.TokenAdminAuthenticationResponse
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.parameter.parametersOf
import receipt.DeviceStatuses
import receipt.withFlag
import receipt.withoutFlag

class DefaultDataRepository(
    private val scope: CoroutineScope,
    private val networkController: NetworkController,
    private val tokenPersistence: TokenPersistence,
    private val accessPersistence: AccessPersistence,
) : DataRepository, KoinComponent {

    override val navigation by inject<Navigation> { parametersOf(scope, this) }

    override val snackbarStateMutableStateFlow: MutableStateFlow<SnackBarState> = MutableStateFlow(SnackBarState.Nothing())
    override val snackbarStateStateFlow = snackbarStateMutableStateFlow.asStateFlow()

    private val populatedCoalitionsMutableStateFlow = MutableStateFlow<TrackedPopulatedCoalition>(TrackedPopulatedCoalition())
    override val populatedCoalitionsStateFlow: StateFlow<TrackedPopulatedCoalition> = populatedCoalitionsMutableStateFlow.asStateFlow()

    private val isPopulatedCoalitionsRefreshingMutableStateFlow = MutableStateFlow<Boolean>(false)
    override val isPopulatedCoalitionsRefreshingStateFlow: StateFlow<Boolean> = isPopulatedCoalitionsRefreshingMutableStateFlow.asStateFlow()

    override val accessLevel: AdminAccess?
        get() = accessPersistence.getAccess()

    init {
        requestUpdatedPopulatedCoalitions()
    }

    override fun setSnackbarState(state: SnackBarState) {
        snackbarStateMutableStateFlow.value = state
    }

    override fun getToken(): String? = tokenPersistence.getToken()

    override fun deleteToken() {
        tokenPersistence.deleteToken()
    }

    override fun setAccess(token: TokenAdminAuthenticationResponse) {
        tokenPersistence.setToken(token.token)
        accessPersistence.setAccess(token.accessLevel.toString())
        navigate(NavigationState.AdminHome())
    }

    override fun requestUpdatedPopulatedCoalitions() {
        scope.launch {
            isPopulatedCoalitionsRefreshingMutableStateFlow.value = true
            populatedCoalitionsMutableStateFlow.value = TrackedPopulatedCoalition(networkController.getPopulatedCoalitions())
            isPopulatedCoalitionsRefreshingMutableStateFlow.value = false
        }
    }

    override suspend fun submitCredentials(email: String, password: String): AdminLoginResponse {
        return networkController.submitCredentials(email, password)
    }

    override suspend fun setMerchantDemo(merchantId: Int, setAsDemo: Boolean): Boolean {
        val success = networkController.setMerchantDemo(merchantId, setAsDemo)
        if (success) {
            populatedCoalitionsMutableStateFlow.update {
                val coalitions = populatedCoalitionsMutableStateFlow.value.populatedCoalitions
                coalitions.mapNotNull { it.merchants.firstOrNull { it.id == merchantId } }?.firstOrNull()?.apply { demo = setAsDemo }
                TrackedPopulatedCoalition(coalitions)
            }
        }
        return success
    }

    override suspend fun submitCoalition(coalitionLongName: String, coalitionShortName: String): Boolean {
        return networkController.submitCoalition(coalitionLongName, coalitionShortName).also {
            requestUpdatedPopulatedCoalitions()
        }
    }

    override suspend fun setDeviceHidden(deviceId: Int, setHidden: Boolean): Boolean {
        val success = networkController.setDeviceHidden(deviceId, setHidden)
        if (success) {
            populatedCoalitionsMutableStateFlow.update {
                val coalitions = it.populatedCoalitions
                coalitions.mapNotNull { it.merchants.mapNotNull { it.locations.mapNotNull { it.devices.firstOrNull { it.id == deviceId } }.firstOrNull() }.firstOrNull() }.firstOrNull()?.apply {
                    statusType = if (setHidden) statusType.withFlag(DeviceStatuses.Hidden) else statusType.withoutFlag(DeviceStatuses.Hidden)
                }
                TrackedPopulatedCoalition(coalitions)
            }
        }
        return success
    }

    override suspend fun getMerchantActivity(): List<MerchantActivity> {
        return networkController.getMerchantActivity()
    }

    override suspend fun getRegions(): List<PopulatedRegion> {
        return networkController.getRegions()
    }

    override suspend fun addLocation(regionId: Int, locationId: Int): Boolean {
        return networkController.addLocation(regionId, locationId)
    }

    override suspend fun removeLocation(regionId: Int, locationId: Int): Boolean {
        return networkController.removeLocation(regionId, locationId)
    }

    override suspend fun newRegion(regionName: String): Int? {
        return networkController.newRegion(regionName)
    }

    override suspend fun getLinxAnalytics(from: LocalDateTime, to: LocalDateTime): List<Analytics> {
        return networkController.getLinxAnalytics(from, to)
    }

    override fun logOut() {
        deleteToken()
        navigate(NavigationState.Login())
    }

    override suspend fun getCoalitionAdminUsers(): List<CoalitionAdminUser> {
        return networkController.getCoalitionAdminUsers()
    }

    override val navigationStateFlow = navigation.navigationStateFlow
    override val backStackStateFlow = navigation.backStackStateFlow
    override fun navigate(navigationState: NavigationState) = navigation.navigate(navigationState)
    override fun popBackStack() = navigation.popBackStack()
    override fun hashStateChangeEvent(newHash: String, locationListener: (String) -> Unit) = navigation.hashStateChangeEvent(newHash, locationListener)
}