package model

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import navigation.Navigation
import navigation.NavigationState
import receipt.Question
import receipt.Receipt
import ui.snackbars.SnackBarState

interface DataRepository {
    val navigation: Navigation
    val merchantInformationMutableStateFlow: MutableStateFlow<MerchantFullInformationV2?>
    val merchantInformationStateFlow: StateFlow<MerchantFullInformationV2?>
    val cardsMutableStateFlow: MutableStateFlow<List<Card?>?>
    val cardsStateFlow: StateFlow<List<Card?>?>
    val consumerInformationMutableStateFlow: MutableStateFlow<ConsumerInformationV2?>
    val consumerInformationStateFlow: StateFlow<ConsumerInformationV2?>
    val paymentsMutableStateFlow: MutableStateFlow<PaymentState>
    val paymentsStateFlow: StateFlow<PaymentState>
    val eReceiptMutableStateFlow: MutableStateFlow<Receipt?>
    val eReceiptStateFlow: StateFlow<Receipt?>
    val orderQuestionMutableStateFlow: MutableStateFlow<Question?>
    val orderQuestionStateFlow: StateFlow<Question?>
    val snackbarStateMutableStateFlow: MutableStateFlow<SnackBarState>
    val snackbarStateStateFlow: StateFlow<SnackBarState>
    val navigationStateFlow: StateFlow<NavigationState>
    val backStackStateFlow: StateFlow<List<NavigationState>>
    val showAppFullScreen: MutableStateFlow<Boolean>

    sealed class PaymentState(val payments: List<OrderPayment>) {
        class Payments(payments: List<OrderPayment>) : PaymentState(payments)
        class Refreshing(payments: List<OrderPayment>) : PaymentState(payments)
    }

    fun setSnackbarState(state: SnackBarState)
    fun setMerchantInformation(merchantInformation: MerchantFullInformationV2?)
    fun setCards(cards: List<Card?>)
    fun setConsumerInformation(consumerInformation: ConsumerInformationV2?)
    fun setPayments(payments: PaymentState)
    fun setEReceipt(eReceipt: Receipt?)
    fun setOrderQuestion(question: Question?)
    fun getToken(): String?
    fun deleteToken()
    fun setToken(token: String)

    suspend fun setConsumerInformationFromToken(onSet: (isSet: Boolean) -> Unit)
    fun loadPaymentsAsync(payments: List<OrderPayment>, consumerLoyaltyProgramGuid: String)
    fun loadEreceiptAsync(orderPaymentGuid: String)
    fun loadQuestionAsync(orderPaymentGuid: String)
    fun answerQuestion(orderPaymentGuid: String, questionId: Int, score: Int)
    suspend fun authenticateGuid(guid: String): String
    suspend fun getMerchantInformationAsync(consumerLoyaltyProgramGuid: String): Payments
    suspend fun requestAuthenticationFromOrderPaymentGuid(guid: String)
    suspend fun submitPhoneNumber(phoneNumber: Long): LoginResponse
    suspend fun bypassLogin(token: String)
    fun onTransactionClicked(orderPaymentGuid: String)
    fun setMerchant(merchant: MerchantFullInformationV2)
    suspend fun getMerchantsNearMe(): List<MerchantSummaryInformation>

    fun logOut()
    fun navigate(navigationState: NavigationState)
    fun popBackStack()
    fun hashStateChangeEvent(newHash: String, locationListener: (String) -> Unit)
    suspend fun cancelEnrollment(consumerLoyaltyProgramGuid: String)

    fun loadSimplifiedEreceiptAsync(orderPaymentGuid: String)
    fun setAppFullScreen(isAppFullScreen: Boolean)
}