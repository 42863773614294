package admin.ui.linxAnalytics

import admin.ui.scrollbar.LazyColumnScrollbar
import admin.ui.scrollbar.ListIndicatorSettings
import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.tween
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Refresh
import androidx.compose.material3.Button
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.unit.dp
import model.Analytics
import org.koin.compose.koinInject

@Composable
fun LinxAnalytics(
    linxAnalyticsViewModel: LinxAnalyticsViewModel = koinInject(),
    toolbarComposable: (@Composable (Modifier) -> Unit) -> Unit,
) {
    toolbarComposable { modifier ->
        Row {
            if (linxAnalyticsViewModel.isAdmin) {
                Button(linxAnalyticsViewModel::requestAnalyticsUpdate) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Text("Refresh Active Devices")

                        val isRefreshing by linxAnalyticsViewModel.isLinxAnalyticsRefreshingStateFlow.collectAsState()
                        var currentRotation by remember { mutableStateOf(0f) }
                        val rotation = remember { Animatable(currentRotation) }
                        LaunchedEffect(isRefreshing) {
                            if (isRefreshing) {
                                rotation.animateTo(
                                    targetValue = currentRotation + 360f,
                                    animationSpec = infiniteRepeatable(
                                        animation = tween(1000, easing = LinearEasing),
                                        repeatMode = RepeatMode.Restart
                                    )
                                ) {
                                    currentRotation = value
                                }
                            } else {
                                rotation.animateTo(
                                    targetValue = 0f,
                                    animationSpec = tween(
                                        durationMillis = 100,
                                    )
                                ) {
                                    currentRotation = value
                                }
                            }

                        }
                        Icon(Icons.Default.Refresh, null, modifier.rotate(rotation.value))
                    }
                }
            }
        }
    }
    Column {
        Row {
            Button(onClick = {linxAnalyticsViewModel.setTimePeriod(LinxAnalyticsViewModel.TimePeriod.Today)}){ Text("Today")}
            Button(onClick = {linxAnalyticsViewModel.setTimePeriod(LinxAnalyticsViewModel.TimePeriod.Month)}){ Text("Month")}
//            Button(onClick = {linxAnalyticsViewModel.setTimePeriod(LinxAnalyticsViewModel.TimePeriod.Custom())}){ Text("Custom")}
        }
        Row {
            Button(onClick = { linxAnalyticsViewModel.setMetric(LinxAnalyticsViewModel.Metric.Platform) }){ Text("Platform")}
            Button(onClick = { linxAnalyticsViewModel.setMetric(LinxAnalyticsViewModel.Metric.Event) }){ Text("Event")}
            Button(onClick = {}){ Text("Metric 3")}
        }
        val _metrics by linxAnalyticsViewModel.metricStateFlow.collectAsState()
        val metrics = _metrics
        when (metrics) {
            is LinxAnalyticsViewModel.AnaltyicsDisplay.Platform -> {
                BarGraph(metrics.barGraphData)
            }
            is LinxAnalyticsViewModel.AnaltyicsDisplay.Event -> {
                BarGraph(metrics.barGraphData)
            }

            else -> {}
        }
    }
    HandleDialogs(linxAnalyticsViewModel)
}

@Composable
fun AnalyticsList(
    modifier: Modifier = Modifier,
    label: String,
    analytics: List<Analytics>,
) {
    Column(modifier = modifier) {
        Text(label)
        val lazyListState = rememberLazyListState()
        LazyColumnScrollbar(
            listState = lazyListState,
            padding = 4.dp,
            alwaysShowScrollBar = true,
            showItemIndicator = ListIndicatorSettings.Disabled,
        ) {
            LazyColumn(
                state = lazyListState,
            ) {
                items(analytics) {
                    Column {
                        Text(
                            modifier = Modifier
                            ,
                            text = it.toString()
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun HandleDialogs(linxAnalyticsViewModel: LinxAnalyticsViewModel) {
    val _dialogState by linxAnalyticsViewModel.dialogStateStateFlow.collectAsState()
    val dialogState = _dialogState

    when (dialogState) {
        is LinxAnalyticsViewModel.DialogState.None -> Unit
        is LinxAnalyticsViewModel.DialogState.Error -> Unit
        is LinxAnalyticsViewModel.DialogState.Success -> Unit
    }
}
