package model.impl

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import model.Card
import model.ConsumerInformationV3
import model.DataRepository
import model.DeleteCard
import model.DeleteCardBody
import model.LoginResponse
import model.MerchantFullInformationV3
import model.MerchantSummaryInformation
import model.NetworkController
import model.OrderPayment
import model.Payments
import navigation.Navigation
import navigation.NavigationState
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.parameter.parametersOf
import persistence.TokenPersistence
import receipt.OptInTypes
import receipt.Question
import receipt.QuestionAnswer
import receipt.Receipt
import toStringOrNull
import ui.snackbars.SnackBarState

class DefaultDataRepository(
    private val scope: CoroutineScope,
    private val networkController: NetworkController,
    private val tokenPersistence: TokenPersistence,
) : DataRepository, KoinComponent {

    override val navigation by inject<Navigation> { parametersOf(scope, this) }

    override val merchantInformationMutableStateFlow: MutableStateFlow<MerchantFullInformationV3?> = MutableStateFlow(null)
    override val merchantInformationStateFlow = merchantInformationMutableStateFlow.asStateFlow()

    override val cardsMutableStateFlow: MutableStateFlow<List<Card?>?> = MutableStateFlow(null)
    override val cardsStateFlow = cardsMutableStateFlow.asStateFlow()

    override val consumerInformationMutableStateFlow: MutableStateFlow<ConsumerInformationV3?> = MutableStateFlow(null)
    override val consumerInformationStateFlow = consumerInformationMutableStateFlow.asStateFlow()

    override val paymentsMutableStateFlow: MutableStateFlow<DataRepository.PaymentState> =
        MutableStateFlow(DataRepository.PaymentState.Refreshing(emptyList()))
    override val paymentsStateFlow = paymentsMutableStateFlow.asStateFlow()

    override val cardDeleteMutableStateFlow: MutableStateFlow<DataRepository.CardState> =
        MutableStateFlow(DataRepository.CardState.Refreshing(emptyList()))
    override val cardDeleteStateFlow = cardDeleteMutableStateFlow.asStateFlow()

    override val eReceiptMutableStateFlow: MutableStateFlow<Receipt?> = MutableStateFlow(null)
    override val eReceiptStateFlow: StateFlow<Receipt?> = eReceiptMutableStateFlow.asStateFlow()

    override val orderQuestionMutableStateFlow: MutableStateFlow<Question?> = MutableStateFlow(null)
    override val orderQuestionStateFlow: StateFlow<Question?> = orderQuestionMutableStateFlow.asStateFlow()

    override val snackbarStateMutableStateFlow: MutableStateFlow<SnackBarState> = MutableStateFlow(SnackBarState.Nothing())
    override val snackbarStateStateFlow = snackbarStateMutableStateFlow.asStateFlow()

    override val showAppFullScreen: MutableStateFlow<Boolean> = MutableStateFlow(false)

    override var tempConsumerLoyaltyProgramGuid: String = ""

    override fun setSnackbarState(state: SnackBarState) {
        snackbarStateMutableStateFlow.value = state
    }

    override fun setMerchantInformation(merchantInformation: MerchantFullInformationV3?) {
        merchantInformationMutableStateFlow.value = merchantInformation
    }

    override fun setCards(cards: List<Card?>) {
        cardsMutableStateFlow.value = cards
    }

    override fun setDeleteCards(cards: DataRepository.CardState) {
        cardDeleteMutableStateFlow.value = cards
    }

    override fun setConsumerInformation(consumerInformation: ConsumerInformationV3?) {
        consumerInformationMutableStateFlow.value = consumerInformation
    }

    override fun setPayments(payments: DataRepository.PaymentState) {
        paymentsMutableStateFlow.value = payments
    }

    override fun setEReceipt(eReceipt: Receipt?) {
        eReceiptMutableStateFlow.value = eReceipt
    }

    override fun setOrderQuestion(question: Question?) {
        orderQuestionMutableStateFlow.value = question
    }

    override fun getToken(): String? = tokenPersistence.getToken()

    override fun deleteToken() {
        tokenPersistence.deleteToken()
    }

    override fun setToken(token: String) {
        tokenPersistence.setToken(token)
    }

    override suspend fun setConsumerInformationFromToken(onSet: (isSet: Boolean) -> Unit) {
        val consumerInformation = networkController.getConsumerInformation()
        if (consumerInformation != null) {
            setConsumerInformation(consumerInformation)
            setCards(consumerInformationStateFlow.value?.cards ?: emptyList())
            onSet(true)
        } else {
            tokenPersistence.deleteToken()
            onSet(false)
        }
    }

    override fun loadPaymentsAsync(payments: List<OrderPayment>, consumerLoyaltyProgramGuid: String) {
        setPayments(DataRepository.PaymentState.Refreshing(payments))
        scope.launch {
            setPayments(
                DataRepository.PaymentState.Payments(
                    getMerchantInformationAsync(
                        consumerLoyaltyProgramGuid
                    ).orderPayment
                )
            )
        }
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    override fun loadPaymentsAndConsumerInformationAsync(
        payments: List<OrderPayment>,
        consumerLoyaltyProgramGuid: String,
    ) {
        setPayments(DataRepository.PaymentState.Refreshing(payments))
        scope.launch {
            val paymentsAndConsumerInformation = networkController.getPaymentsAndConsumerInformation(consumerLoyaltyProgramGuid)
            setMerchantInformation(
                paymentsAndConsumerInformation.merchantFullInformationV3
            )
            val merchantInformation = paymentsAndConsumerInformation.merchantFullInformationV3

            consumerInformationMutableStateFlow.update { consumerInformation ->
                if (consumerInformation == null) return@update consumerInformation

                consumerInformation.copy(merchants = consumerInformation.merchants.map {
                    if (it?.consumerLoyaltyProgramGuid == merchantInformation.consumerLoyaltyProgramGuid) {
                        merchantInformation
                    } else {
                        it
                    }
                })
            }

            setPayments(
                DataRepository.PaymentState.Payments(
                    paymentsAndConsumerInformation.payments.orderPayment
                )
            )
        }
    }

    override fun loadEreceiptAsync(orderPaymentGuid: String) {
        setEReceipt(null)
        setOrderQuestion(null)
        scope.launch {
            val eReceipt = networkController.getEReceiptInformation(
                orderPaymentGuid,
            )
            if (eReceipt.orderPaymentGuid.isNotBlank()) {
                setEReceipt(eReceipt)
                loadQuestionAsync(orderPaymentGuid)
            } else {
                snackbarStateMutableStateFlow.value = SnackBarState.InvalidEreceipt()
            }
        }
    }

    override fun loadQuestionAsync(orderPaymentGuid: String) {
        setOrderQuestion(null)
        scope.launch {
            val nextQuestion = networkController.getQuestions(orderPaymentGuid)
            if (nextQuestion.isNotEmpty()) {
                setOrderQuestion(nextQuestion.first())
            }
        }
    }

    override fun answerQuestion(orderPaymentGuid: String, questionId: Int, score: Int) {
        setOrderQuestion(null)
        scope.launch {
            networkController.answerQuestion(QuestionAnswer(orderPaymentGuid, questionId, score))
            loadQuestionAsync(orderPaymentGuid)
        }
    }

    override suspend fun authenticateGuid(guid: String): String {
        return networkController.authenticateGuid(guid)
    }

    override suspend fun getMerchantInformationAsync(consumerLoyaltyProgramGuid: String): Payments {
        return networkController.getMerchantInformation(consumerLoyaltyProgramGuid)
    }

    override suspend fun requestAuthenticationFromOrderPaymentGuid(guid: String) {
        return networkController.requestAuthenticationFromOrderPaymentGuid(guid)
    }

    override suspend fun submitPhoneNumber(phoneNumber: Long): LoginResponse {
        return networkController.submitPhoneNumber(phoneNumber)
    }

    override suspend fun bypassLogin(token: String) {
        setToken(token)
        setMerchantInformation(null)
        setConsumerInformationFromToken { isSet ->
            if (isSet) {
                navigate(NavigationState.ConsumerInformation())
            } else {
                navigate(NavigationState.Login())
            }
        }
    }

    override fun logOut() {
        deleteToken()
        navigate(NavigationState.Login())
    }

    override fun setMerchant(merchant: MerchantFullInformationV3) {
        val lastSelectedMerchant = merchantInformationStateFlow.value
        if (lastSelectedMerchant != merchant) {
            setMerchantInformation(merchant)
            loadPaymentsAsync(
                emptyList(),
                merchant.consumerLoyaltyProgramGuid!!
            )
        } else {
            loadPaymentsAsync(
                paymentsStateFlow.value.payments,
                merchantInformationStateFlow.value!!.consumerLoyaltyProgramGuid!!
            )
        }
        navigate(NavigationState.MerchantInformation(merchant.consumerLoyaltyProgramGuid!!))
    }

    override fun onTransactionClicked(orderPaymentGuid: String) {
        if (eReceiptStateFlow.value?.orderPaymentGuid != orderPaymentGuid) {
            loadEreceiptAsync(orderPaymentGuid)
        }
        navigate(NavigationState.EReceipt(orderPaymentGuid))
    }

    override val navigationStateFlow = navigation.navigationStateFlow
    override val backStackStateFlow = navigation.backStackStateFlow
    override fun navigate(navigationState: NavigationState) = navigation.navigate(navigationState)
    override fun popBackStack() = navigation.popBackStack()
    override fun hashStateChangeEvent(newHash: String, locationListener: (String) -> Unit) = navigation.hashStateChangeEvent(newHash, locationListener)
    override suspend fun getMerchantsNearMe(): List<MerchantSummaryInformation> = networkController.getMerchantsNearMe(
        consumerId = consumerInformationStateFlow.value?.consumer?.consumerId.toStringOrNull() ?: ""
    )
    override suspend fun cancelEnrollment(consumerLoyaltyProgramGuid: String) {
        val response = networkController.cancelEnrollment(
            consumerLoyaltyProgramGuid,
        )
        val tempMerchantList = consumerInformationStateFlow.value?.merchants?.map {
            if (it?.consumerLoyaltyProgramGuid == consumerLoyaltyProgramGuid) {
                it.copy(optInType = it.optInType?.plus(OptInTypes.OptedOut.optInType))
            } else { it }
        }.orEmpty()
        val newConsumerInformation = with(consumerInformationStateFlow.value) {
            this?.copy(
                merchants = tempMerchantList
            )
        }
        setConsumerInformation(newConsumerInformation)
        navigation.navigate(NavigationState.ConsumerInformation())
    }

    override fun loadSimplifiedEreceiptAsync(orderPaymentGuid: String) {
        setEReceipt(null)
        scope.launch {
            val eReceipt = networkController.getEReceiptInformation(
                orderPaymentGuid,
            )
            if (eReceipt.orderPaymentGuid.isNotBlank()) {
                setEReceipt(eReceipt)
            }
        }
    }

    override fun setAppFullScreen(isAppFullScreen: Boolean) {
        if (isAppFullScreen != showAppFullScreen.value) {
            showAppFullScreen.value = isAppFullScreen
        }
    }

    override fun loadDeleteCardAsync(cards: List<DeleteCard>, consumerLoyaltyProgramGuid: String) {
        setDeleteCards(DataRepository.CardState.Refreshing(cards))
        scope.launch {
            val cards: List<DeleteCard> = networkController.getDemoAdminCards(consumerLoyaltyProgramGuid)
            if (cards.isNotEmpty()){
                tempConsumerLoyaltyProgramGuid = consumerLoyaltyProgramGuid
                setDeleteCards(
                    DataRepository.CardState.Cards(cards)
                )
            }
        }
    }

    override fun deleteCard(card: Card) {
        scope.launch {
            networkController.deleteCard(
                DeleteCardBody(
                    tempConsumerLoyaltyProgramGuid,
                    card
                )
            )
        }
    }
}