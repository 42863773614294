package model

import receipt.Question
import receipt.QuestionAnswer
import receipt.Receipt

interface NetworkController {
    suspend fun getConsumerInformation(): ConsumerInformationV3?
    suspend fun getMerchantInformation(consumerLoyaltyProgramGuid: String, forceRefresh: Boolean = false): Payments
    suspend fun getEReceiptInformation(orderPaymentGuid: String, forceRefresh: Boolean = false): Receipt
    suspend fun getQuestions(orderPaymentGuid: String): List<Question>
    suspend fun answerQuestion(questionAnswer: QuestionAnswer)
    suspend fun sendBusinessEnrollmentInformation(referredBusiness: ReferredBusiness): Unit
    suspend fun submitPhoneNumber(phoneNumber: Long): LoginResponse
    suspend fun getMerchantsNearMe(consumerId: String): List<MerchantSummaryInformation>
    suspend fun authenticateGuid(guid: String): String
    suspend fun requestAuthenticationFromOrderPaymentGuid(guid: String)
    suspend fun cancelEnrollment(consumerLoyaltyProgramGuid: String): Boolean
    suspend fun getDemoAdminCards(consumerLoyaltyProgramGuid: String): List<DeleteCard>
    suspend fun deleteCard(deletedCardBody: DeleteCardBody)
    suspend fun getPaymentsAndConsumerInformation(consumerLoyaltyProgramGuid: String): PaymentsAndMerchantInformation
}